.tw-footer {

    *,
    ::before,
    ::after {
        box-sizing: border-box;
        /* 1 */
        border-width: 0;
        /* 2 */
        border-style: solid;
        /* 2 */
        border-color: #e5e7eb;
        /* 2 */
    }

    ::before,
    ::after {
        --tw-content: '';
    }

    a {
        color: inherit;
        text-decoration: inherit;
    }

    [hidden] {
        display: none;
    }

    ol,
    ul,
    menu {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li{
        line-height: 1.25rem !important;
    }

    ._sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
      }
      
      ._pointer-events-none {
        pointer-events: none;
      }
      
      ._absolute {
        position: absolute;
      }
      
      ._relative {
        position: relative;
      }
      
      ._bottom-0 {
        bottom: 0px;
      }
      
      ._right-0 {
        right: 0px;
      }
      
      ._mx-auto {
        margin-left: auto;
        margin-right: auto;
      }
      
      ._mt-10 {
        margin-top: 2.5rem;
      }
      
      ._mt-3 {
        margin-top: 0.75rem;
      }
      
      ._mt-7 {
        margin-top: 1.75rem;
      }
      
      ._mt-8 {
        margin-top: 2rem;
      }
      
      ._flex {
        display: flex;
      }
      
      ._grid {
        display: grid;
      }
      
      ._grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      
      ._items-center {
        align-items: center;
      }
      
      ._gap-2 {
        gap: 0.5rem;
      }
      
      ._gap-8 {
        gap: 2rem;
      }
      
      ._gap-x-6 {
        -moz-column-gap: 1.5rem;
             column-gap: 1.5rem;
      }
      
      ._space-y-3 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
      }
      
      ._border-t {
        border-top-width: 1px;
      }
      
      ._border-neutral-12 {
        --tw-border-opacity: 1;
        border-color: RGB(59 80 91 / var(--tw-border-opacity));
      }
      
      ._bg-neutral-14 {
        --tw-bg-opacity: 1;
        background-color: RGB(33 37 41 / var(--tw-bg-opacity));
      }
      
      ._fill-accent-1 {
        fill: #20B1FD;
      }
      
      ._py-20 {
        padding-top: 5rem;
        padding-bottom: 5rem;
      }
      
      ._pb-24 {
        padding-bottom: 6rem;
      }
      
      ._pt-7 {
        padding-top: 1.75rem;
      }
      
      ._font-open-sans {
        font-family: Open Sans, sans-serif;
      }
      
      ._text-sm {
        font-size: 0.875rem;
        line-height: 1.25rem;
      }
      
      ._text-xs {
        font-size: 0.75rem;
        line-height: 1rem;
      }
      
      ._font-bold {
        font-weight: 700;
      }
      
      ._uppercase {
        text-transform: uppercase;
      }
      
      .\!_leading-none {
        line-height: 1 !important;
      }
      
      ._text-accent-1 {
        --tw-text-opacity: 1;
        color: RGB(32 177 253 / var(--tw-text-opacity));
      }
      
      ._text-neutral-10 {
        --tw-text-opacity: 1;
        color: RGB(126 150 165 / var(--tw-text-opacity));
      }
      
      ._text-neutral-5 {
        --tw-text-opacity: 1;
        color: RGB(222 229 237 / var(--tw-text-opacity));
      }
      
      .hover\:_text-accent-1:hover {
        --tw-text-opacity: 1;
        color: RGB(32 177 253 / var(--tw-text-opacity));
      }
      
      .hover\:_text-neutral-10:hover {
        --tw-text-opacity: 1;
        color: RGB(126 150 165 / var(--tw-text-opacity));
      }
      
      ._group:hover .group-hover\:_fill-neutral-1 {
        fill: #FFFFFF;
      }
      
      ._group:hover .group-hover\:_text-accent-1 {
        --tw-text-opacity: 1;
        color: RGB(32 177 253 / var(--tw-text-opacity));
      }
      
      @media (min-width: 650px) {
        .md\:_order-1 {
          order: 1;
        }
      
        .md\:_order-2 {
          order: 2;
        }
      
        .md\:_mt-0 {
          margin-top: 0px;
        }
      
        .md\:_flex {
          display: flex;
        }
      
        .md\:_grid {
          display: grid;
        }
      
        .md\:_grid-cols-2 {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
      
        .md\:_items-center {
          align-items: center;
        }
      
        .md\:_justify-between {
          justify-content: space-between;
        }
      
        .md\:_gap-8 {
          gap: 2rem;
        }
      }
      
      @media (min-width: 1230px) {
        .xl\:_col-span-2 {
          grid-column: span 2 / span 2;
        }
      
        .xl\:_mt-0 {
          margin-top: 0px;
        }
      
        .xl\:_grid {
          display: grid;
        }
      
        .xl\:_grid-cols-3 {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }
      
        .xl\:_gap-8 {
          gap: 2rem;
        }
      }      
}