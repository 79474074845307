.cart-icon {
    margin-left: auto;
    width: 20px;
    height: 20px;
    font-size: 18px;
    font-family: "Font Awesome 5 Pro";
    font-weight: 300; 
    cursor: pointer;
    position: relative;

    &:before {
        content: "\f217";
    }

    .count {
        position: absolute;
        top: -8px;
        right: -8px;
        background: #00537c;
        height: 16px;
        width: 16px;
        border-radius: 8px;
        color: #ffffff;
        font-weight: 600;
        font-size: 11px;
        line-height: 14px;
        text-align: center;
    }
}