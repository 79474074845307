.contact-chat {
    white-space: nowrap;
    text-align: right;
    display: flex;
    align-items: flex-start;
    cursor: pointer;     

    &-btn,
    &-link {
        color: rgb(33, 37, 41);
    }

    &-btn {
        &:before {
            font-size: 12px;
            font-family: "Font Awesome 5 Pro";
            font-weight: 900; 
            content: "\f111";
            color: #2cb14a;
            margin: 0 5px 0 0;   
        }
    }
}