// Override the font-size assigned to the body element from the semantic-ui plugin
html, body {
    font-size: 16px !important;
    font-family: 'Open Sans', sans-serif !important;
  }
  
  ul li {
    line-height: 28px !important;
  }
  
  .main-footer .product-column li {
    line-height: 50px !important;
  }
  
  .no-scroll {
    overflow: hidden;
  }

  #root {
    font-family: 'Open Sans', sans-serif;
    min-height: 715px;
    display: block;
    //reassign font size for the semantic-ui plugin
    font-size:14px;
    .ui.menu {
      font-size: 1.125rem;
    }
  
    .ui {
      &.menu {
        font-family: "Open Sans";
      }
  
      &.container {
        width: 1440px;
      }
  
      &.grid>.stretched.row>.column>* {
        flex-grow: unset;
      }
    }
  
    .products-app {
      margin: 100px 0;
    }
}

@import 'loader.scss';
@import 'overrides.scss';