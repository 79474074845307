.si {
    width: 230px;
    height: 40px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(199, 215, 226);
    border-radius: 4px;
    cursor: pointer;
    margin: 0 0 8px 0;
    display: flex;
    padding: 10px 15px;
    align-items: flex-start;

    &-addon {
        width: 100%;
        overflow: hidden;
    }

    &-active,
    &:hover {
        background: rgb(255, 255, 255);
        border: 1px solid rgb(9, 126, 178);
        border-radius: 4px;
    }

    &.disabled {
        cursor: not-allowed;
        /*border: 1px solid rgb(199, 215, 226) !important;*/
    }

    &-checkbox {
        margin: 0 5px 0 0;
        line-height: 0.8em;
    }

    &-ico {
        font-family: "Font Awesome 5 Pro";
        line-height: 1em;
        color: rgb(33, 37, 41);
        font-size: 16px;
        width: 20px;
        height: 20px;
        margin: 0 5px;

        &-desktop:before {
            content: "\f108";
        }
        &-mobile:before {
            content: "\f10b";
        }  
        &-web:before {
            content: "\f37e";
        }              
    }

    &-title {
        color: rgb(33, 37, 41);
        font-size: 16px;
        line-height: 1em;
        .saleprice {
            display: inline-flex;
            &:before {
                content: "-";
                margin: 0 5px;
            }
        }
    }

    &-info {
        margin-left: auto;
        width: 14px;
        height: 14px;
        color: rgb(142, 173, 191);
        font-size: 14px;
        font-family: "Font Awesome 5 Pro";
        font-weight: normal; 

        &:hover {
            color: rgb(9, 126, 178);
        }

        &:before {
            content: "\f05a";
        }
    }
}