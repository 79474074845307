.store-header {

    background-color: #fff;
    width: 100%;    
    box-shadow: 0 1px 3px 0 rgba(#112838,0.2);
    position: fixed;
    top: 0;
    z-index: 1000;

    &-wrap {
        margin: 0 auto;
        max-width: 1440px;
        display: flex;
        align-items: flex-start;
        height: 65px;

        .logo {
            width: 175px;
            height: 32px;
            margin: 15px 0 0 0;

            @media only screen and (max-width: 920px) {
                margin: 15px 0 0 20px;
            }

            img {
                height: 25px;
            }
        }    
        
        .cart {
            margin-left: auto;
            padding: 0 10px 0 0;
        }

        .contact {
            padding: 0 30px 0 20px;
            margin: 20px 0 0 0;

            @media only screen and (max-width: 920px) {
                 padding: 0 15px 0 10px;
            }            
        }
    }
}