.cart-checkout {
    z-index: 1010;
    width: 100%;
    height: 100%;    
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(117,132,145,.7);
    display: flex;
    align-items: center;

    &-wrap {
        position: relative;
        background: #ffffff;
        border-radius: 5px;

        width: 250px;
        height: 200px;
        background-size: cover;
        left: 50%;
        margin-top: -100px;
        margin-left: -125px;   
        
        
        .wait {

            position: absolute;
            bottom: 40px;
            width: 250px;
            text-align: center;
            font-size: 18px;            
        }

        .loader {
            top: 30% !important;
        }
    }
}