#root {
.cards {
    .card {
      height: auto;
      border-radius: 0;
      @media only screen and (min-width: 755px) and (max-width: 991px) {
        width: 49%;
        margin: 0.375em auto;
      }
      &.prod-family {
        &-none {
          border-top: 2px solid #ff720d;
        }
        &-independent {
          border-top: 2px solid #51e4ea;
        }
        &-swagger {
          border-top: 2px solid #85ea2c;
        }
        &-cucumber {
          border-top: 2px solid #21d96c;
        }
        &-zephyr {
          border-top: 2px solid #14d1fb;
        }        
        &-readyapi {
          border-top: 2px solid #fcdc00;
        }                         
        /*&-dev {
          border-top: 2px solid #23d96c;
        }
        &-api {
          border-top: 2px solid #fcdc00;
        }*/
      }

      .product-information {
        padding: 20px 20px 10px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        .prod-header {
          display: flex;

          .prod-name {
            margin: auto 0 auto 10px;
            font-size: 18px;
            color: #173647;
            &:hover {
                color: gray;
                cursor: pointer;
            }
          }
          .prod-icon {
            width: 40px;
            height: 40px;
            &:hover {
              cursor: pointer;
                + .prod-name {
                color: gray;
              }
            }
          }
        }
        .addon-name {
          width: 100%;
          font-weight: 600;
          margin: 15px 0;
        }
        .prod-desc {
          display: block;
          padding: 15px 0;
          line-height: 22px;
          width: 100%;
        }
      }
      .price-starting-at {
        color: #3b505b;
      }
      .prod-price {
        color: #212529;
        font-size: 20px;
        font-weight: 600;
        width: 100%;
        display: block;
      }

      .prod-actions {
        display: block;
        width: 100%;
        align-self: flex-end;
      }

      .product-action-link {
        border-bottom: 1px solid #e8e8e8;
        padding: 12px 0;
        display: block;
        font-size: 16px;

        &:last-child {
          border: none;
        }

        .prod-link {
          color: #000;
          font-size: 15px;
          cursor: pointer;
          
          &-buy {
            color: #097eb2;
            cursor: pointer;
            &:hover, &:active {
              color: lighten(#097eb2, 10%)
            }
          }

          &:hover {
            color: lighten(#333, 30%);
          }

          .fal {
            margin-left: 3px;
          }
        }
      }
    }
  }
}