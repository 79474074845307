.cart {
    margin: 20px 0 0 0;
    width: 100%;

    &-group {
        margin: 20px 0 40px;
        border: 1px solid rgb(228, 234, 241);
        padding: 40px 30px 20px;
        position: relative;

        &-product {
            display: flex;
            position: absolute;
            left: 20px;
            top: -13px;
            align-items: flex-start;
            background: #fff;
            padding: 0 10px;

            .prod-icon {
                width: 30px;
                height: 30px;
            }

            .prod-title {
                margin: 0 0 0 5px;
                padding: 3px 0 0 0;
                font-size: 20px;
            }
        }

        &-items {

        }
    }
}