 #root {   
    .sort-menu {
      display: flex;
      align-items: flex-start;
      @media (max-width: 921px) {
        .left.menu {
          display: none;
        }
      }
    }
    .secondary {
        a {
            font-size: 14px;
            font-family: "Open Sans";
        }

        .left{
          max-width: 750px;
          &.menu {
            .count-name {
              display: inline-block;
              padding: 5px;
              font-size: 14px;
              margin: 5px;
            }
            .selected-filters {
              &-item,
              &-item-clearall {
                margin: 5px;
                display: inline-block;
                border-radius: 15px;
                background-color: #f3f4f7;
                border: 1px solid #dedede;
                font-weight: 600;
                font-size: 12px;
                padding: 5px 10px;
                &-clear {                  
                  vertical-align: middle;
                  padding-left: 5px;
                  cursor: pointer;
                  &:hover {
                    color: gray;
                  }
                }
              }
              &-item-clearall {
                background-color: #dedede;
                cursor: pointer;
                & i {
                  display: none;
                }
              }
            }
          }
        }
        
        .right {
          @media (max-width: 921px) { 
            margin: 0 auto;
          }
          align-items: flex-start;
            .filter-title {
              align-self: center;
              font-size: 14px;
              padding-right: 10px;
            }
            &.menu {
                .searchbar-input {
                  height:40px;
                  width: 300px;
                  .fa-search {
                    position: absolute;
                    top: 10px;
                    left: 15px;
                  }
                }
                .item {                    
                    border: 1px solid #dedede;

                    border-radius: 0;
                    margin: 0;

                    &:nth-of-type(1) {
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                        border-right: none;
                    }

                    &:last-child{
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                        border-left: none;
                    }

                    &:hover,
                    &.active {
                        background-color: #f3f4f7;
                    }
                    
                }
            }
        }
      }
    }