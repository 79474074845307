.page {
    width: 1170px;
    margin: 90px auto;
    font-size: 16px;
    color: rgb(33, 37, 41);

    @media only screen and (max-width: 1170px) {
        width: auto;
        padding: 0 20px;
    }

    .sales-clear-button {
        display: inline-flex;
        cursor: pointer;
        color: #4183C4;
        &:hover {
            color: #1e70bf;
        }
    }

    &-header {
        &-back {
            display: block;
            margin: 0 0 30px 0;
        }

        &-logo {
            width: 200px;
        }

        .info {
            display: flex;
            align-items: flex-start;

            &-title {
                color: rgb(33, 37, 41);
                font-size: 20px;
            }
            &-count {
                color: rgb(33, 37, 41);
                font-size: 16px;
                margin-left: auto;
            }
        }
    }

    &-content {
        display: flex;
        min-height: 300px;
        
        @media only screen and (max-width: 920px) {
            flex-wrap: wrap;
        }

        &.product {
            border: 1px solid rgb(216, 221, 228);
            margin: 0 0 40px 0;
        }

        &-left {
            width: 690px;
            min-width: 550px;

            @media only screen and (max-width: 920px) {
                width: auto;
                min-width: auto;
            }            
        }

        &-right {
            width: 480px;    
            border-left: 1px solid #d8dde4;
        }

        @media only screen and (max-width: 920px) {
            &-right,
            &-left {
                width: 100%;
            }

            &-right {
                border-top: 1px solid #d8dde4;
                border-left: none;
            }
        }
    }

    &-tooltip {
        width: 300px;
        color: #173647 !important;
        padding: 5px;
        background-color: lightblue !important;       
    }

    .page-license {
        padding: 40px 50px;

        @media only screen and (max-width: 920px) {
            padding: 20px 25px;
        }
    
        &-option {
            margin: 0 0 25px 0;
    
            &.disabled {
                opacity: 0.6;
                cursor: not-allowed;

                label {
                    cursor: not-allowed !important;
                }
            }
    
            &-title {
                font-weight: 600;
                font-size: 16px;
                margin: 0 0 5px 0;
            }

            &-clear {
                font-weight: 400;
                font-size: 15px;
                color: #4183C4;
                cursor: pointer;
                float: right;

                &:after {
                    clear: both;
                    content: '\f057';
                    width: 14px;
                    height: 14px;
                    color: #4183C4;
                    font-size: 15px;
                    font-family: "Font Awesome 5 Pro";
                    margin: 0 0 0 5px;
                }
            }
    
            input[type=radio] {
                cursor: pointer;
            }
            label {
                margin: 0 20px 0 4px;
                font-size: 16px;
                cursor: pointer;
            }

            &-info {
                margin-left: auto;
                width: 14px;
                height: 14px;
                color: rgb(142, 173, 191);
                font-size: 14px;
                font-family: "Font Awesome 5 Pro";
                font-weight: normal; 
                display: inline;
                margin: 0 0 0 5px;
                cursor: pointer;
                
                &:hover {
                    color: rgb(9, 126, 178);
                }
        
                &:before {
                    content: "\f05a";
                }
            }

            &-select {
                display: flex;

                @media only screen and (max-width: 920px) {
                    flex-wrap: wrap;
                }                
            }

            &-radio {
                @media only screen and (max-width: 920px) {
                    margin: 0 0 8px 0;
                }                
            }

        }
    }    
}