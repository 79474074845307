.cart-preview {
    background: rgb(248, 250, 251);
    padding: 40px 50px;
    height: 100%;

    @media only screen and (max-width: 920px) {
        padding: 20px 25px;
    }

    &-header {
        display: flex;
        justify-content: space-between;
        padding: 0 0 20px 0;

        .title {
            font-weight: 600;
            font-size: 16px;
        }

        .count {
            font-size: 15px;
        }
    }

    &-item {
        display: flex;
        align-items: baseline;
        padding: 0 0 10px 0;

        @media only screen and (max-width: 920px) {
            align-items: flex-start;
        }

        &-checkbox {
            margin: 0 5px 0 0;

            &.disabled {
                width: 15px;
            }
        }

        &-title {
            padding: 0 15px 0 0;

            .license-type {
                color: rgb(86, 109, 122);
                font-size: 13px;
            }

            label {
                margin: 0;
                cursor: pointer;
            }
        }

        &-dots {
            flex-grow: 1;
            background-image: linear-gradient(to right, black 20%, rgba(255,255,255,0) 0%);
            background-position: bottom;
            background-size: 5px 1px;
            background-repeat: repeat-x;  
            height: 5px;          
        }
        
        &-count {
          
            padding: 0 15px;

            .disabled {
                font-size: 13px;
            }

            .rw-widget-picker {
                width: 60px !important; 
                min-height:  25px !important;

                .rw-widget-input {
                    width: 30px !important;   
                    font-size: 15px;
                    padding: 0 5px;
                    text-align: center;
                }
            }

        }

        &-price {
            width: 60px;  
            min-width: 60px;          
            text-align: right;
        }
    }

    &-footer {
        padding: 20px 0 0 0;       
    }

    .btn-preview {
        border-radius: 4px;
        text-align: center;
        font-size: 16px;
        padding: 8px 0;
        margin: 0 0 20px 0;
        cursor: pointer;

        &-add-cart {            
            border: 1px solid rgb(9, 126, 178);
            color: rgb(9, 126, 178);

            &:hover {
                background: rgb(9, 126, 178);
                color: #ffffff;
            }
        }

        &-buy-now {
            background: rgb(9, 126, 178);
            border: 1px solid rgb(9, 126, 178);
            color: #ffffff;

            &:hover {
                background: #ffffff;
                color: rgb(9, 126, 178);
            }            
        }
    }
}
