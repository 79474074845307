.cart-menu {
    z-index: 1005;
    width: 100%;
   /* height: 100%;    */
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(117,132,145,.7);
    
    &-sidebar {
        display: flex;
        height: 100%;
    

        &.left {   
            width: 100%;         
        }

        &.right {
            min-width: 500px;
            margin-right: -500px;
            background-color: #fff;           
            margin-left: auto;
            height: 100%;
            display: flex;
            flex-direction: column;
            transition: margin-right .2s ease-in-out;

            &.show {
                margin-right: 0;
            }

            @media only screen and (max-width: 920px) {
                min-width: 360px;
                margin-right: -360px;
            }
        }
    }

    &-header {
        display: flex;
        width: 100%;
        padding: 40px 40px 0 40px;

        @media only screen and (max-width: 920px) {
            padding: 20px 20px 0 20px;
        }

        .title {
            font-size: 18px;
            font-weight: 600;
        }

        .btn-close {
            margin-left: auto;

            &:before {
                font-family: "Font Awesome 5 Pro";
                color: rgb(33, 37, 41);
                font-size: 18px;
                width: 20px;
                height: 20px;
                margin: 0 5px;
                content: "\f00d";
                cursor: pointer;
            }
        }
    }

    &-content {
        margin: 20px 0px 0 40px;
        padding: 0 40px 0 0;
        flex-grow: 2;
        overflow-y: auto;

        @media only screen and (max-width: 920px) {
            margin: 20px 0px 0 20px;
            padding: 0 20px 0 0;
        }

        &-added {
            width: 100%;
            padding: 5px 20px;
            margin: 0 0 30px 0;
            background: rgba(44, 177, 74, 0.06);
            border: 1px solid rgb(44, 177, 74);
            border-radius: 4px;
            color: rgb(33, 37, 41);
            font-size: 14px;

            &:before {
                width: 20px;
                height: 20px;
                font-size: 18px;
                font-family: "Font Awesome 5 Pro";
                font-weight: 300; 
                color: rgb(44, 177, 74);               
                content: "\f00c";
                margin: 0 10px 0 0;
            }            
        }
    }

    &-footer {
        padding: 20px 40px;
        font-size: 16px;

        @media only screen and (max-width: 920px) {
            padding: 10px 20px;
        }
        .actions {
            margin: 20px 0 0 0;
            border-top: 1px solid rgb(228, 234, 241);
            padding: 20px 0 0 0;

            &-checkout {
                display: flex;
                align-items: baseline;

                .link-cart {
                    color: rgb(39, 127, 184);
                    cursor: pointer;
                }

                .btn-checkout {
                    border-radius: 4px;
                    text-align: center;
                    font-size: 16px;
                    padding: 8px 0;
                    margin: 0 0 10px 0;
                    cursor: pointer;
                    background: rgb(9, 126, 178);
                    border: 1px solid rgb(9, 126, 178);
                    color: #ffffff;   
                    width: 200px;
                    margin-left: auto;
                }
            }
        }
    }
}