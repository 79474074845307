.order-step {
    display: flex;
    margin: 25px 0;

    .item {
        font-size: 14px;
        font-weight: 400;
        color: rgb(86, 109, 122);
        padding: 0 10px 10px 10px;
        border-bottom: 2px solid rgb(222, 229, 237);

        &:first-child {
            padding-left: 0;
        }

        &.active {
            color: rgb(33, 37, 41);
            border-bottom: 2px solid rgb(255, 143, 15);
        }
    }   
}