.ui.secondary.filter-button.menu {
    display: none;
}
@media (max-width: 920px) {
    .ui.secondary.filter-button.menu {
        position: fixed;
        display: block;
        top: 65px;
        left: 0px;
        width: 100%;
        height: 50px;
        z-index: 3;
        &:after {
            display: none;
        }
        .filter-button-area {
            padding: 10px 20px 30px 20px;
            display: flex;
            width: 102%;
            justify-content: space-between;
            height: 50px;
            background-color: #f8fafb;
            box-shadow: 0px 1px 3px 0px rgba(17, 40, 56, 0.2);
            .right-place {
                width: auto;
                margin-bottom: 0;
                vertical-align: middle;
                .count-name {
                    font-size: 14px;
                    padding-top: 3px;
                }
            }
            .left-place {
                width: auto;
                .filters-button {
                    font-size: 12px;
                    font-weight: 600;
                    background-color: #fff;
                    border-radius: 4px;
                    border: 1px solid #c7d7e2;
                    padding: 3px 10px 3px 5px;
                    text-transform: uppercase;
                    .fal {
                        width: 10px;
                        margin: 0 5px;  
                        font-weight: 600;
                    }
                }
            }
        }
    }
    .overlay.show {
        z-index: -1;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(117, 132, 145, 0.7);
    }
}
