/**
* react-widgets number picker
*/

#root {
    .rw-picker-btn:disabled, fieldset[disabled] .rw-picker-btn {
        background-color: #ffffff;
    }

    .rw-state-focus .rw-widget-picker {
        box-shadow: none;
        border: 1px solid #097eb2;
    }
}