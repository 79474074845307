.page-cart {
    .page-content {
        flex-wrap: wrap;
    }

    &-content {
        width: 100%;
        margin: 20px 0 0 ;
    }

    &-message {
        width: 100%;
        text-align: center;
        margin: 60px 0 0 0;
    }

    &-actions {
        width: 100%;
        padding: 0 0 0 30px;

        @media only screen and (max-width: 920px) {
            padding: 0;
        }

        .btn-cart-remove {
            display: inline-block;
            background: #566d7a;
            border: 1px solid #566d7a;
            border-radius: 4px;
            text-align: center;
            padding: 10px 25px;
            cursor: not-allowed;
            color: #ffffff;

            @media only screen and (max-width: 920px) {
                display: block;
                padding: 8px 0;
            }

            &.active {
                background: rgb(9, 126, 178);
                cursor: pointer;
                border: 1px solid rgb(9, 126, 178);

                &:hover {
                    background: #ffffff;
                    color: rgb(9, 126, 178);
                    
                }                     
            }
        }
    }

    &-checkout {
        width: 100%;
        margin: 20px 0 0;
        padding: 0 0 0 30px;
        display: flex;

        @media only screen and (max-width: 920px) {
            flex-wrap: wrap;
            padding: 0;         
        }

        .subtotal {
            width: 80%;
            padding: 0 40px 0 0;

            @media only screen and (max-width: 920px) {
                width: 100%; 
                padding: 0;
            }            
        }

        .checkout {
            width: 20%;

            @media only screen and (max-width: 920px) {
                width: 100%;
            }            
        }

        .btn-checkout {
            border-radius: 4px;
            text-align: center;
            font-size: 16px;
            padding: 8px 0;
            margin: 0 0 10px 0;
            cursor: pointer;            
            background: rgb(9, 126, 178);
            border: 1px solid rgb(9, 126, 178);
            color: #ffffff;

            &:hover {
                background: #ffffff;
                color: rgb(9, 126, 178);
            }            
        }
    }
}