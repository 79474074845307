.cart-subtotal {
    display: block;
    width: 100%;

    &-info {
        display: flex;
        align-items: baseline;

        &-title {
            font-weight: 600;
            font-size: 18px;
        }
        &-dots {
            flex-grow: 1;
            background-image: linear-gradient(to right, black 20%, rgba(255,255,255,0) 0%);
            background-position: bottom;
            background-size: 5px 1px;
            background-repeat: repeat-x;  
            height: 5px;  
            margin: 0 15px;
        }
        &-price {
            margin-left: auto;
            font-weight: 600;
            font-size: 20px;
        }
    }

    &-desc {
        color: rgb(86, 109, 122);
        font-size: 13px;
        margin: 0 0 40px;
    }
}