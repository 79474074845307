#root, .products-app {
    min-height: 500px;
    @media only screen and (max-width: 767px) {
        .ui {
            &.two.doubling.cards > .card {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            }
            &.grid > .stretched.row > .column > * {
                flex-wrap: wrap;
            }
            .secondary { 
                .left {
                    width: 100%;
                }
                .right {
                    width: 100%;
                    margin-bottom: 20px;
                    &.menu { 
                        .searchbar-input {
                            height: 40px;
                            width: 100%;
                        }
                    }
                }
                
            }
        }
        .addons-hero { 
            margin: 45px 0 20px 0 !important;
            &-title {
                margin-bottom: 30px !important;
            }
        }
    }
    .addons-hero {
        min-height: 102px;
        background: #f8fafb;
        border: 1px solid #d8dde4;
        border-radius: 0px;
        padding: 20px 0;
        margin: 45px 0 0 0;
        .container {
            padding: 0 30px;
        }
        &-title {
            height: 24px;
            color: rgb(59, 80, 91);
            font-size: 18px;
            font-family: "Open Sans";
            font-weight: 600;
            letter-spacing: 0px;
            margin-bottom: 10px;
        }
        &-text {
            margin: 0 auto;
            color: #3b505b;
            font-size: 14px;
        }
    }
}