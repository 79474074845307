#root {
    @media (max-width: 921px) {
        .five.wide.column {
            display: none !important;
        }
    }
    .accordion-filter {
        background-color: #fff;
        @media (max-width: 921px) {
            margin-left: -30000px;
            position: fixed;
            z-index: 3;
            transition: margin-left .2s ease-in-out;
            margin-top: 15px;
            height: 550px;
            width: 360px;
            &.show {
                margin-left: -29px;
                z-index: 999;
                overflow-y: auto;
                min-height: 1px;
                max-height: calc(100% + 105px);
                height: 100%;
                width: 360px;
                @media (min-width: 767px) {
                    margin-left: -15px;
                }
                @media (max-height: 930px) {
                    height: calc(100vh - 14%)
                }
                @media (max-height: 600px) {
                    height: calc(100vh - 20%)
                }
            }
        }
        .accordion-title {
            font-size: 18px;
            font-weight: 500;            
            padding: 15px 0;            
            .fal {
                padding-right: 5px;
            }
            .filters-clear {
              float: right;
            }
            @media (max-width: 921px) {
                display: none;
            }
                
        }
        i.icon, i.icons {
            font-size: 0.7em;
            float: right;
        }
        .menu {
                border-radius: 0;
                box-shadow: none;
                border-left: none;
                border-right: none;
                border-top: none;
                border-bottom: none;
                .filter-title {
                    text-transform: uppercase;
                    font-weight: 600;
                    color: #3b505b;
                    font-size: 14px;
                    overflow: hidden;
                    white-space: nowrap;
                    width: 100%;
                    &-text {
                        z-index: 1;
                        width: auto;
                        display: block;
                        &:after {
                            content:'';
                            display: inline-block;
                            height: 1px;
                            position: relative;
                            vertical-align: middle;
                            left: 1em;
                            width: 100%;
                            background: #d8dde4
                        }
                    }
                }
                .item {
                    padding: 0;
                    border-left: none;
                    border-right: none;

                    .title {
                        font-size: 16px;
                        padding: 20px 10px;
                        text-transform: uppercase;
                        font-weight: bold;
                    }

                    .content {
                        padding-top: 0;
                        background-color: #fff;
                        margin: 0;
                        padding: 0 20px 20px 20px;
                        border-top: none;
                    }
                }
            }

            .grouped.fields {
                margin-top: 30px;
                .integrat-title {
                    font-size: 20px;
                    padding-bottom: 15px;
            }
                .checkbox {
                    margin: 0 0 20px 0;     
                    
                    &:last-child {
                        margin: 0;
                    }
                }

                .checkbox-item {
                    margin: 0;
                    display: inline-block;                      

                    label {
                        margin-bottom: 5px;                        
                        font-size: 16px;
                        padding-left: 25px;
                        &::before {
                            border-radius: 0px;
                        }
                    }

                    &-count {
                        display: inline-block;
                        font-size: 16px;
                        margin-left: 5px;
                        &.disabled {
                            color: #b4b5b7;
                        }
                    }
                }
            }
    } 
}   