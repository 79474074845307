.addons {
    padding: 40px 50px;
    border-top: 1px solid rgb(216, 221, 228);

    @media only screen and (max-width: 920px) {
        padding: 20px 25px;
    }
    
    &-header {
        color: rgb(33, 37, 41);
        cursor: pointer;

        &:before {
            float: left;
            width: 20px;
            height: 50px;            
            font-size: 18px;
            font-family: "Font Awesome 5 Pro";       
            content: "\f054";
            margin: 0 5px 0 0;                        
        }

        &.open {
            &:before {           
                content: "\f078";          
            }
        }

        &-title {
            font-weight: 600;
            font-size: 16px;
        }

        &-desc {
            font-size: 13px;
            color: rgb(86, 109, 122);
            margin: 0 0 20px 0;
        }
    }

    &-content {
        &.open {
            display: none;
        }
    }
}