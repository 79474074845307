.si-radio {
    display: block;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(199, 215, 226);
    border-radius: 4px;
    padding: 15px;
    margin: 0 0 10px 0;

    &-item {
        margin-bottom: 20px;
        display: flex;
        align-items: baseline;

        &:last-child {
            margin-bottom: 0;
        }

        label {
            margin: 0 5px 0 5px !important;
        }
    }

    &-active,
    &:hover {
        border: 1px solid rgb(9, 126, 178);
    }    

    &.disabled {
        opacity: 0.6;
        cursor: not-allowed;

        input[type="radio"],
        label {
            cursor: not-allowed !important;
        }

        &:hover {
            border: 1px solid rgb(199, 215, 226);
        }          
    }
}